.servers-container {
  padding: 2rem 0;
}

.game-selector {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 40px;
}

.game-button {
  min-width: 160px;
}

.plan-card {
  background: white;
  border-radius: 24px;
  padding: 3rem;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  animation: fadeIn 0.5s ease-out;
}

.plan-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 24px;
}

.plan-name {
  min-width: 120px;
  text-align: center;
}

.plan-price {
  text-align: center;
  color: #2196f3;
  margin-bottom: 32px;
}

.price-period {
  font-size: 0.5em;
  color: #666;
}

.plan-details {
  margin-bottom: 32px;
}

.specs-list,
.features-list {
  list-style: none;
  padding: 0;
}

.specs-list li,
.features-list li {
  padding: 8px 0;
  display: flex;
  align-items: center;
}

.specs-list li:before,
.features-list li:before {
  content: "✓";
  color: #4caf50;
  margin-right: 8px;
}

.order-button {
  margin-top: 16px;
  padding: 12px;
}

@media (max-width: 600px) {
  .plan-price {
    font-size: 2.5rem;
  }
  
  .plan-details {
    text-align: center;
  }
} 

.game-card {
  transition: transform 0.2s;
}

.game-card:hover {
  transform: translateY(-4px);
}

.game-image {
  height: 200px;
  object-fit: cover;
} 

.plan-specs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.spec {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.spec-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: #4299e1;
}

.spec-label {
  font-size: 0.9rem;
  color: #718096;
}

.pricing-card {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.pricing-card:hover {
  transform: translateY(-8px);
} 

.pricing-card {
  position: relative;
  overflow: hidden;
}

.popular-tag {
  position: absolute;
  top: 12px;
  right: -30px;
  transform: rotate(45deg);
  background: #2196f3;
  padding: 5px 40px;
}

.popular-text {
  color: white;
  font-size: 0.8rem;
  font-weight: 500;
}

.card-header {
  text-align: center;
  margin-bottom: 2rem;
}

.price-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 1rem;
}

.currency {
  font-size: 1.5rem;
  color: #2196f3;
  margin-right: 4px;
}

.price-dollars {
  font-size: 3rem;
  font-weight: 700;
  color: #2196f3;
  line-height: 1;
}

.price-cents {
  font-size: 1.5rem;
  color: #2196f3;
}

.price-period {
  font-size: 1rem;
  color: #666;
  margin-left: 4px;
}

.specs-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
}

.spec-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.spec-icon {
  color: #2196f3;
}

.features-divider {
  margin: 2rem 0;
}

.included-features {
  margin-top: 2rem;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.check-icon {
  color: #4caf50;
}

.value-badge {
  position: absolute;
  top: 16px;
  right: 16px;
}

.order-button {
  margin-top: auto;
  padding: 12px;
  font-weight: 600;
}

/* Add responsive styles as needed */
@media (max-width: 600px) {
  .specs-grid {
    grid-template-columns: 1fr;
  }
  
  .price-dollars {
    font-size: 2.5rem;
  }
} 

/* Store and Server Styles */
.server-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease-in-out;
}

.server-card:hover {
  transform: translateY(-5px);
}

.server-card-content {
  flex-grow: 1;
}

.server-card-actions {
  padding: 16px;
  display: flex;
  justify-content: center;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .servers-container {
    padding: 1rem 0;
  }
} 

.store-container {
  min-height: 100vh;
  width: 100%;
  background-color: #111;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.store-hero-section {
  min-height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  position: relative;
  backdrop-filter: blur(10px);
}

.hero-title {
  color: #4caf50;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.store-card {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 0 20px rgba(76, 175, 80, 0.2);
  margin: 0 auto;
  max-width: 400px;
}

.store-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 30px rgba(76, 175, 80, 0.4);
}

.game-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 8px;
}

.game-background {
  position: relative;
  height: 200px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.game-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  mix-blend-mode: multiply;
}

.feature-icon {
  color: #4caf50;
  background: rgba(76, 175, 80, 0.1);
  padding: 1px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.feature-row:hover .feature-icon {
  transform: scale(1.1);
  background: rgba(76, 175, 80, 0.2);
}

/* Circle Graph Styles */
.pie-chart {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: conic-gradient(
    #4caf50 0% 95%, /* Adjust the percentage based on popularity */
    #ccc 95% 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}

/* Add any additional styles from Home.css that are relevant */

/* Ensure no extra margin on the right side */
.container {
  margin-right: 0;
} 