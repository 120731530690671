.dashboard-container {
  min-height: 80vh;
  background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.9) 100%);
}

.dashboard-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}

.dashboard-title {
  font-weight: 700;
  background: linear-gradient(45deg, #2196F3, #FF4081);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 30px rgba(33, 150, 243, 0.3);
}

.dashboard-card {
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.dashboard-stat-card {
  transition: transform 0.2s;
}

.dashboard-stat-card:hover {
  transform: translateY(-4px);
}

.dashboard-button {
  background: linear-gradient(45deg, #2196F3, #FF4081);
  color: #fff;
  font-weight: 600;
}

.dashboard-button:hover {
  background: linear-gradient(45deg, #1976D2, #D81B60);
}

.dashboard-refresh-button {
  margin-right: 1rem;
}

.dashboard-refresh-button:hover {
  background-color: rgba(33, 150, 243, 0.1);
}

.dashboard-error-alert {
  margin-bottom: 1.5rem;
  background-color: rgba(211, 47, 47, 0.1);
  color: #ff1744;
}

.dashboard-error-alert .MuiAlert-icon {
  color: #ff1744;
}

.dashboard-header {
  margin-bottom: 2rem;
}

.dashboard-header h1 {
  font-size: 2rem;
  color: #2d3748;
  margin-bottom: 1.5rem;
}

.dashboard-summary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.summary-card {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.summary-card h3 {
  color: #4a5568;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.summary-card p {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2d3748;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.dashboard-servers,
.dashboard-charts {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dashboard-servers h2,
.dashboard-charts h2 {
  font-size: 1.25rem;
  color: #2d3748;
  margin-bottom: 1rem;
}

.server-status-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

@media (max-width: 1024px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
  }
} 

.status-card {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.resource-chart {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  min-height: 300px;
} 