* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.home-container {
  min-height: 100vh;
  width: 100%;
  background-color: #111;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.hero-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  position: relative;
  background: linear-gradient(
    to bottom,
    rgba(28, 28, 28, 0.95) 0%,
    rgba(32, 32, 32, 0.95) 100%
  );
  backdrop-filter: blur(10px);
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    linear-gradient(45deg, rgba(255, 255, 255, 0.05) 25%, transparent 25%),
    linear-gradient(-45deg, rgba(255, 255, 255, 0.05) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, rgba(255, 255, 255, 0.05) 75%),
    linear-gradient(-45deg, transparent 75%, rgba(255, 255, 255, 0.05) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  opacity: 0.1;
}

.features-section {
  padding: 80px 0;
  background: linear-gradient(
    to bottom,
    rgba(17, 17, 17, 1) 0%,
    rgba(28, 28, 28, 0.95) 100%
  );
  position: relative;
  overflow: hidden;
}

.features-section::before,
.features-section::after {
  content: '';
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  filter: blur(45px);
  z-index: 0;
}

.features-section::before {
  top: -100px;
  left: -100px;
  background: rgba(52, 211, 153, 0.15);
  animation: float 8s ease-in-out infinite;
}

.features-section::after {
  bottom: -100px;
  right: -100px;
  background: rgba(52, 211, 153, 0.1);
  animation: float 8s ease-in-out infinite reverse;
}

@keyframes float {
  0%, 100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(20px, 20px);
  }
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  z-index: 1;
  position: relative;
}

.location-badge {
  display: inline-flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 50px;
  margin-bottom: 2rem;
  backdrop-filter: blur(10px);
  color: white;
}

.hero-title {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, #4ade80 30%, #34D399 90%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
  position: relative;
}

.hero-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, transparent, #4ade80, transparent);
  border-radius: 2px;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 3rem;
  line-height: 1.5;
}

.hero-cta {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.premium-features {
  padding: 6rem 2rem;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}

.stats-section {
  padding: 80px 0;
  background: linear-gradient(
    to bottom,
    rgba(17, 17, 17, 1) 0%,
    rgba(28, 28, 28, 0.95) 100%
  );
  position: relative;
  overflow: hidden;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.stat-card {
  text-align: center;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.stat-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transition: 0.5s;
}

.stat-card:hover::before {
  left: 100%;
}

.stat-card .stat-value {
  font-size: 2.5rem;
  font-weight: bold;
  color: #4ade80;
  margin-bottom: 0.5rem;
}

.stat-card .stat-label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }

  .premium-features {
    padding: 4rem 1rem;
  }

  .stats-section {
    padding: 3rem 1rem;
  }

  .hero-cta {
    flex-direction: column;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-content, .premium-features, .stats-section {
  animation: fadeIn 1s ease-out;
}

/* Add smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Add scroll progress indicator */
.scroll-progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, #4ade80, #34D399);
  transform-origin: 0%;
  z-index: 1000;
}

.games-section {
  padding: 80px 0;
  background: linear-gradient(
    to bottom,
    rgba(28, 28, 28, 0.95) 0%,
    rgba(17, 17, 17, 1) 100%
  );
  position: relative;
  overflow: hidden;
}

.games-section::before,
.games-section::after {
  content: '';
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  filter: blur(45px);
  z-index: 0;
}

.games-section::before {
  top: -100px;
  right: -100px;
  background: rgba(52, 211, 153, 0.1);
  animation: float 8s ease-in-out infinite;
}

.games-section::after {
  bottom: -100px;
  left: -100px;
  background: rgba(52, 211, 153, 0.05);
  animation: float 8s ease-in-out infinite reverse;
}

/* Smooth section transitions and spacing */
.section-transition {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(
      to bottom,
      transparent,
      rgba(17, 17, 17, 0.8)
    );
    pointer-events: none;
  }
}

/* Enhanced section spacing and responsive padding */
.hero-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  position: relative;
  background: linear-gradient(
    to bottom,
    rgba(28, 28, 28, 0.95) 0%,
    rgba(32, 32, 32, 0.95) 100%
  );
  backdrop-filter: blur(10px);
}

.features-section,
.games-section,
.stats-section {
  position: relative;
  padding: clamp(40px, 8vw, 80px) 0;
  overflow: hidden;

  /* Inner content padding */
  .section-content {
    padding: 0 clamp(16px, 4vw, 40px);
  }
}

/* Grid spacing adjustments */
.grid-container {
  display: grid;
  gap: clamp(16px, 4vw, 32px);
  padding: clamp(16px, 4vw, 40px);
}

/* Responsive container padding */
@media (max-width: 768px) {
  .container-padding {
    padding: 0 16px;
  }
  
  .section-heading {
    font-size: clamp(24px, 5vw, 40px);
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .container-padding {
    padding: 0 32px;
  }
}

@media (min-width: 1025px) {
  .container-padding {
    padding: 0 40px;
  }
}

/* Smooth scrolling behavior */
html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px; /* Adjust for fixed header if present */
}

/* Section transitions */
.section-fade-up {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.section-fade-up.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Enhanced hover effects */
.hover-lift {
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
}

/* Optimized grid layouts */
.responsive-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
  gap: clamp(16px, 4vw, 32px);
}

/* Section dividers */
.section-divider {
  position: relative;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0.1) 50%,
    transparent 100%
  );
  margin: clamp(40px, 8vw, 80px) 0;
}

/* Responsive styles for the node map and other elements */
@media (max-width: 768px) {
  .node-map {
    width: 100%;
    height: auto;
  }
  .node {
    width: 10px;
    height: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .node-map {
    width: 80%;
    height: auto;
  }
  .node {
    width: 15px;
    height: 15px;
  }
}

@media (min-width: 1025px) {
  .node-map {
    width: 70%;
    height: auto;
  }
  .node {
    width: 20px;
    height: 20px;
  }
}

/* Ensure navbar and footer are responsive */
.navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
} 