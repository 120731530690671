.profile-container {
  padding: 2rem 0;
}

.profile-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.9) 100%);
}

.profile-loading-text {
  margin-top: 1rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2rem;
}

.profile-card {
  background: rgba(45, 45, 45, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
}

.profile-details-card {
  background: rgba(45, 45, 45, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.profile-avatar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.profile-avatar {
  width: 120px;
  height: 120px;
  border: 4px solid rgba(33, 150, 243, 0.3);
  box-shadow: 0 0 20px rgba(33, 150, 243, 0.2);
  transition: all 0.3s ease;
}

.profile-avatar:hover {
  border-color: rgba(33, 150, 243, 0.6);
  box-shadow: 0 0 30px rgba(33, 150, 243, 0.4);
}

.profile-avatar-upload-button {
  background-color: #2196F3 !important;
  transition: all 0.3s ease !important;
}

.profile-avatar-upload-button:hover {
  background-color: #1976D2 !important;
  transform: scale(1.1);
}

.profile-username {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  text-align: center;
  background: linear-gradient(45deg, #2196F3, #FF4081);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.profile-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  padding: 0.5rem;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.profile-info-item:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.profile-info-icon {
  margin-right: 0.75rem;
  color: #2196F3;
  font-size: 1.25rem !important;
}

.profile-member-since {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.profile-role {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.profile-credits {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.profile-credits-value {
  font-weight: 600;
  color: #4CAF50;
}

.profile-server-limit {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.profile-server-limit-value {
  font-weight: 600;
  color: #FF9800;
}

.profile-referral-code {
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  border: 1px dashed rgba(255, 255, 255, 0.2);
}

.profile-referral-label {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.5rem;
}

.profile-referral-code-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-referral-code-text {
  font-family: monospace;
  font-size: 1rem;
  color: #FF4081;
  letter-spacing: 1px;
}

.profile-copy-button {
  color: rgba(255, 255, 255, 0.5) !important;
  transition: all 0.2s ease !important;
}

.profile-copy-button:hover {
  color: #2196F3 !important;
  background-color: rgba(33, 150, 243, 0.1) !important;
}

.profile-connected-accounts {
  margin-top: 1.5rem;
}

.profile-connected-accounts-title {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.75rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.profile-connected-account-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.profile-connected-account-icon {
  margin-right: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
}

.profile-connected-account-name {
  flex: 1;
  color: rgba(255, 255, 255, 0.7);
}

.profile-connected-account-status {
  margin-left: auto;
}

.profile-tabs {
  background-color: rgba(255, 255, 255, 0.05);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.profile-tab {
  color: rgba(255, 255, 255, 0.7) !important;
  transition: all 0.2s ease;
  text-transform: none !important;
  font-size: 0.9rem !important;
  min-height: 64px !important;
}

.profile-tab:hover {
  color: #2196F3 !important;
  background-color: rgba(33, 150, 243, 0.05);
}

.profile-tab.Mui-selected {
  color: #2196F3 !important;
}

.profile-divider {
  background-color: rgba(255, 255, 255, 0.1);
}

.profile-tab-content {
  padding: 2rem;
}

.profile-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.profile-section-title {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
}

.profile-action-buttons {
  display: flex;
  gap: 1rem;
}

.profile-edit-button {
  color: #2196F3 !important;
  border-color: rgba(33, 150, 243, 0.3) !important;
  transition: all 0.2s ease !important;
}

.profile-edit-button:hover {
  background-color: rgba(33, 150, 243, 0.1) !important;
  border-color: #2196F3 !important;
}

.profile-cancel-button {
  color: rgba(255, 255, 255, 0.7) !important;
  transition: all 0.2s ease !important;
}

.profile-cancel-button:hover {
  color: #ff1744 !important;
  background-color: rgba(255, 23, 68, 0.1) !important;
}

.profile-save-button {
  color: #fff !important;
  background-color: #2196F3 !important;
  transition: all 0.2s ease !important;
}

.profile-save-button:hover {
  background-color: #1976D2 !important;
}

.profile-field-container {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 0 1rem;
  transition: all 0.2s ease;
}

.profile-field-container:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.profile-field-icon {
  color: rgba(255, 255, 255, 0.5);
  margin-right: 1rem;
}

.profile-field {
  color: #fff;
}

.profile-field .MuiOutlinedInput-root {
  color: #fff;
}

.profile-field .MuiOutlinedInput-root fieldset {
  border-color: transparent;
}

.profile-field .MuiOutlinedInput-root:hover fieldset {
  border-color: rgba(255, 255, 255, 0.2);
}

.profile-field .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #2196F3;
}

.profile-field .MuiInputLabel-root {
  color: rgba(255, 255, 255, 0.7);
}

.profile-update-password-button {
  margin-top: 1rem;
  background: linear-gradient(45deg, #2196F3, #1976D2) !important;
  transition: all 0.3s ease !important;
  padding: 0.75rem 1.5rem !important;
}

.profile-update-password-button:hover {
  background: linear-gradient(45deg, #1976D2, #0D47A1) !important;
  box-shadow: 0 4px 20px rgba(25, 118, 210, 0.4) !important;
}

.profile-notification-settings {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.profile-notification-settings:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.profile-notification-setting {
  margin: 0 !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

.profile-notification-description {
  margin-top: 0.5rem;
  margin-left: 2.5rem;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.85rem;
}

.profile-notification-actions {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

.profile-save-notifications-button {
  background: linear-gradient(45deg, #2196F3, #1976D2) !important;
  transition: all 0.3s ease !important;
}

.profile-save-notifications-button:hover {
  background: linear-gradient(45deg, #1976D2, #0D47A1) !important;
  box-shadow: 0 4px 20px rgba(25, 118, 210, 0.4) !important;
}

.profile-alert {
  margin-bottom: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.profile-alert-error {
  background-color: rgba(211, 47, 47, 0.1);
  color: #ff1744;
  border-left: 4px solid #ff1744;
}

.profile-alert-success {
  background-color: rgba(76, 175, 80, 0.1);
  color: #4caf50;
  border-left: 4px solid #4caf50;
}

.profile-oauth-button {
  width: 100%;
  margin-bottom: 1rem;
}

.profile-oauth-button-connected {
  color: #4CAF50;
  border-color: #4CAF50;
}

.profile-oauth-button-disconnected {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

.profile-switch.Mui-checked {
  color: #2196F3;
} 