.billing-container {
  padding: 2rem 0;
}

.billing-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.9) 100%);
}

.billing-loading-text {
  margin-top: 1rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2rem;
}

.billing-card {
  background: rgba(45, 45, 45, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.billing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
}

.billing-details-card {
  background: rgba(45, 45, 45, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.billing-tabs {
  background-color: rgba(255, 255, 255, 0.05);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.billing-tab {
  color: rgba(255, 255, 255, 0.7) !important;
  transition: all 0.2s ease;
  text-transform: none !important;
  font-size: 0.9rem !important;
  min-height: 64px !important;
}

.billing-tab:hover {
  color: #2196F3 !important;
  background-color: rgba(33, 150, 243, 0.05);
}

.billing-tab.Mui-selected {
  color: #2196F3 !important;
}

.billing-divider {
  background-color: rgba(255, 255, 255, 0.1);
}

.billing-tab-content {
  padding: 2rem;
}

.billing-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.billing-section-title {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  position: relative;
  padding-bottom: 0.5rem;
}

.billing-section-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  background: linear-gradient(45deg, #2196F3, #FF4081);
  border-radius: 3px;
}

.billing-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  padding: 0.5rem;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.billing-info-item:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.billing-info-icon {
  margin-right: 0.75rem;
  color: #2196F3;
  font-size: 1.25rem !important;
}

.billing-plan-card {
  background: rgba(45, 45, 45, 0.9);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
}

.billing-plan-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
}

.billing-plan-card.active {
  border-color: #2196F3;
  box-shadow: 0 0 20px rgba(33, 150, 243, 0.2);
}

.billing-plan-name {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  background: linear-gradient(45deg, #2196F3, #FF4081);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.billing-plan-price {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.9);
}

.billing-plan-price-period {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.6);
}

.billing-plan-features {
  margin: 1.5rem 0;
  flex-grow: 1;
}

.billing-plan-feature {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
}

.billing-plan-feature-icon {
  margin-right: 0.75rem;
  color: #4CAF50;
  font-size: 1.25rem !important;
}

.billing-plan-button {
  margin-top: auto !important;
  background: linear-gradient(45deg, #2196F3, #1976D2) !important;
  color: white !important;
  border-radius: 30px !important;
  padding: 0.75rem 1.5rem !important;
  font-weight: 600 !important;
  text-transform: none !important;
  transition: all 0.3s ease !important;
  box-shadow: 0 4px 20px rgba(33, 150, 243, 0.3) !important;
}

.billing-plan-button:hover {
  background: linear-gradient(45deg, #1976D2, #0D47A1) !important;
  box-shadow: 0 6px 25px rgba(33, 150, 243, 0.5) !important;
  transform: translateY(-2px);
}

.billing-plan-button.current {
  background: linear-gradient(45deg, #4CAF50, #2E7D32) !important;
  box-shadow: 0 4px 20px rgba(76, 175, 80, 0.3) !important;
}

.billing-plan-button.current:hover {
  background: linear-gradient(45deg, #2E7D32, #1B5E20) !important;
  box-shadow: 0 6px 25px rgba(76, 175, 80, 0.5) !important;
}

.billing-payment-method {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.2s ease;
}

.billing-payment-method:hover {
  background-color: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.2);
}

.billing-payment-method.default {
  border-color: #2196F3;
  box-shadow: 0 0 10px rgba(33, 150, 243, 0.2);
}

.billing-payment-icon {
  margin-right: 1rem;
  font-size: 2rem !important;
  color: #2196F3;
}

.billing-payment-details {
  flex-grow: 1;
}

.billing-payment-card-number {
  font-family: monospace;
  letter-spacing: 1px;
}

.billing-payment-expiry {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
}

.billing-payment-actions {
  display: flex;
  gap: 0.5rem;
}

.billing-transaction-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.billing-transaction-table th {
  background-color: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  text-align: left;
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.billing-transaction-table td {
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.7);
}

.billing-transaction-table tr:hover td {
  background-color: rgba(255, 255, 255, 0.03);
}

.billing-transaction-status {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
}

.billing-transaction-status.completed {
  background-color: rgba(76, 175, 80, 0.15);
  color: #4CAF50;
}

.billing-transaction-status.pending {
  background-color: rgba(255, 152, 0, 0.15);
  color: #FF9800;
}

.billing-transaction-status.failed {
  background-color: rgba(244, 67, 54, 0.15);
  color: #F44336;
}

.billing-transaction-status.refunded {
  background-color: rgba(156, 39, 176, 0.15);
  color: #9C27B0;
}

.billing-transaction-amount {
  font-weight: 600;
}

.billing-transaction-amount.positive {
  color: #4CAF50;
}

.billing-transaction-amount.negative {
  color: #F44336;
}

.billing-add-payment-button {
  background: linear-gradient(45deg, #2196F3, #1976D2) !important;
  color: white !important;
  border-radius: 30px !important;
  padding: 0.75rem 1.5rem !important;
  font-weight: 600 !important;
  text-transform: none !important;
  transition: all 0.3s ease !important;
  box-shadow: 0 4px 20px rgba(33, 150, 243, 0.3) !important;
}

.billing-add-payment-button:hover {
  background: linear-gradient(45deg, #1976D2, #0D47A1) !important;
  box-shadow: 0 6px 25px rgba(33, 150, 243, 0.5) !important;
  transform: translateY(-2px);
}

.billing-alert {
  margin-bottom: 1.5rem;
  border-radius: 8px;
  animation: slideDown 0.3s ease-out forwards;
  overflow: hidden;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.billing-alert-error {
  background-color: rgba(244, 67, 54, 0.1) !important;
  color: #F44336 !important;
  border: 1px solid rgba(244, 67, 54, 0.3) !important;
}

.billing-alert-success {
  background-color: rgba(76, 175, 80, 0.1) !important;
  color: #4CAF50 !important;
  border: 1px solid rgba(76, 175, 80, 0.3) !important;
}

.billing-stats-card {
  background: rgba(45, 45, 45, 0.9);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.billing-stats-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
}

.billing-stats-title {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.billing-stats-value {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  background: linear-gradient(45deg, #2196F3, #FF4081);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.billing-stats-info {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
}

.billing-stats-icon {
  margin-right: 0.5rem;
  color: #2196F3;
}

.billing-credit-card-form {
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 2rem;
}

.billing-form-field {
  margin-bottom: 1.5rem !important;
}

.billing-form-field .MuiOutlinedInput-root {
  background-color: rgba(0, 0, 0, 0.2);
}

.billing-form-field .MuiOutlinedInput-root fieldset {
  border-color: rgba(255, 255, 255, 0.1);
}

.billing-form-field .MuiOutlinedInput-root:hover fieldset {
  border-color: rgba(255, 255, 255, 0.3);
}

.billing-form-field .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #2196F3;
}

.billing-form-field .MuiInputLabel-root {
  color: rgba(255, 255, 255, 0.7);
}

.billing-submit-button {
  background: linear-gradient(45deg, #2196F3, #1976D2) !important;
  color: white !important;
  border-radius: 30px !important;
  padding: 0.75rem 1.5rem !important;
  font-weight: 600 !important;
  text-transform: none !important;
  transition: all 0.3s ease !important;
  box-shadow: 0 4px 20px rgba(33, 150, 243, 0.3) !important;
}

.billing-submit-button:hover {
  background: linear-gradient(45deg, #1976D2, #0D47A1) !important;
  box-shadow: 0 6px 25px rgba(33, 150, 243, 0.5) !important;
  transform: translateY(-2px);
}

.billing-cancel-button {
  background-color: transparent !important;
  color: rgba(255, 255, 255, 0.7) !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-radius: 30px !important;
  padding: 0.75rem 1.5rem !important;
  font-weight: 600 !important;
  text-transform: none !important;
  transition: all 0.3s ease !important;
  margin-right: 1rem !important;
}

.billing-cancel-button:hover {
  background-color: rgba(255, 255, 255, 0.05) !important;
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.billing-invoice-link {
  color: #2196F3;
  text-decoration: none;
  transition: all 0.2s ease;
}

.billing-invoice-link:hover {
  color: #64B5F6;
  text-decoration: underline;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.billing-fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(33, 150, 243, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0);
  }
}

.billing-pulse {
  animation: pulse 2s infinite;
} 