.legal-container {
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(28, 28, 28, 0.98) 0%,
    rgba(32, 32, 32, 0.95) 100%
  );
  padding: 2rem 0;
  position: relative;
  backdrop-filter: blur(10px);
  overflow-x: hidden;
  border-radius: 32px;
  animation: none;
  overflow: hidden;
}

.legal-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(
      circle at 50% 50%,
      rgba(74, 222, 128, 0.1),
      transparent 60%
    ),
    radial-gradient(
      circle at 80% 20%,
      rgba(52, 211, 153, 0.08),
      transparent 50%
    ),
    radial-gradient(
      circle at 20% 80%,
      rgba(16, 185, 129, 0.08),
      transparent 50%
    );
  filter: blur(60px);
  opacity: 0.7;
  animation: flowingBackground 20s ease-in-out infinite;
  pointer-events: none;
}

@keyframes flowingBackground {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  25% {
    transform: translate(-2%, 2%) scale(1.02);
  }
  50% {
    transform: translate(2%, -1%) scale(0.98);
  }
  75% {
    transform: translate(-1%, -2%) scale(1.01);
  }
}

.legal-header {
  background: rgba(28, 28, 28, 0.98);
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 1.5rem;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(74, 222, 128, 0.2);
  position: relative;
  overflow: hidden;
}

.legal-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(74, 222, 128, 0.3),
    transparent
  );
}

.legal-title {
  font-size: 3.5rem;
  font-weight: bold;
  background: linear-gradient(45deg, #4ade80 30%, #34D399 90%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
  position: relative;
  margin-bottom: 2rem;
  text-align: center;
}

.legal-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, transparent, #4ade80, transparent);
  border-radius: 2px;
}

.legal-metadata {
  background: rgba(74, 222, 128, 0.08);
  padding: 1.5rem;
  border-radius: 12px;
  margin-top: 1.5rem;
  border: 1px solid rgba(74, 222, 128, 0.1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.legal-metadata:hover {
  background: rgba(74, 222, 128, 0.1);
  border-color: rgba(74, 222, 128, 0.2);
  transform: translateY(-2px);
}

.legal-content-wrapper {
  max-width: 900px; /* Limit content width for better readability */
  margin: 0 auto;
  padding: 0 2rem;
}

.legal-section {
  background: rgba(28, 28, 28, 0.98);
  border: 1px solid rgba(74, 222, 128, 0.2);
  backdrop-filter: blur(10px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  margin-bottom: 2rem;
  padding: 0;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  scroll-margin-top: 2rem;
}

.legal-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  cursor: pointer;
  width: 100%;
  transition: all 0.2s ease;
  background: transparent;
  position: relative;
  z-index: 2;
  pointer-events: auto;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 1rem;
  pointer-events: auto;
}

.section-icon {
  font-size: 20px !important;
  color: #4ade80;
  padding: 6px;
  pointer-events: auto;
}

.legal-section:hover .section-icon {
  transform: none;
  background: none;
}

.legal-section-wrapper {
  padding: 0 2rem 1rem 2rem;
  position: relative;
  transform: translateZ(0);
  will-change: transform;
  z-index: 1;
  pointer-events: auto;
  margin-top: -1px;
}

.legal-section-preview {
  display: none; /* Remove the preview entirely */
}

.preview-content {
  filter: blur(2px);
  opacity: 0.7;
  transform: scale(0.99);
  padding: 1.5rem 2rem;
}

.content-blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 70%,
    rgba(28, 28, 28, 0.8) 100%
  );
  pointer-events: none;
  backdrop-filter: none;
}

.legal-section-title {
  font-size: 1.3rem !important;
  color: #4ade80 !important;
  font-weight: 600 !important;
  margin-bottom: 0.5rem !important;
  letter-spacing: 0.5px;
}

.legal-subsection {
  margin-bottom: 1.5rem;
  border-left: 2px solid rgba(74, 222, 128, 0.2);
  padding-left: 1.5rem;
  position: relative;
}

.legal-subsection::before {
  content: '';
  position: absolute;
  left: -2px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: linear-gradient(
    to bottom,
    rgba(74, 222, 128, 0.4),
    rgba(74, 222, 128, 0.1)
  );
}

.subsection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.03);
  transition: all 0.2s ease;
  margin-bottom: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.subsection-header:hover {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(74, 222, 128, 0.2);
  transform: translateX(4px);
}

.subsection-title-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.subsection-icon {
  color: #4ade80;
  display: flex;
  align-items: center;
  padding: 8px;
  background: rgba(74, 222, 128, 0.1);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.subsection-header:hover .subsection-icon {
  background: rgba(74, 222, 128, 0.2);
  transform: scale(1.05);
}

.bullet-points {
  margin-left: 1rem;
  padding: 0.5rem 0;
}

.legal-bullet-point {
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  margin-bottom: 0.5rem;
  transition: all 0.2s ease;
}

.bullet-header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.bullet-title-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.bullet-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.bullet-icon {
  color: #4ade80;
  display: flex;
  align-items: center;
  padding: 6px;
  background: rgba(74, 222, 128, 0.1);
  border-radius: 6px;
}

.legal-description {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.01);
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  margin-top: 0.5rem;
}

/* Add smooth transitions */
.MuiCollapse-root {
  transition: height 0.4s cubic-bezier(0.34, 1.56, 0.64, 1) !important;
}

.MuiCollapse-wrapper {
  transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1) !important;
}

.MuiCollapse-wrapperInner {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1) !important;
}

.MuiCollapse-entered .MuiCollapse-wrapperInner {
  opacity: 1;
  transform: translateY(0);
}

.legal-section-date {
  font-size: 0.85rem !important;
  color: rgba(255, 255, 255, 0.5) !important;
  margin-bottom: 0.5rem !important;
  font-family: monospace !important;
}

.expand-button {
  color: #4ade80 !important;
  background: rgba(74, 222, 128, 0.1) !important;
  transition: all 0.3s ease !important;
  width: 36px;
  height: 36px;
  pointer-events: auto !important;
  z-index: 3;
}

.expand-button:hover {
  background: rgba(74, 222, 128, 0.2) !important;
  transform: scale(1.1);
}

.expand-button.expanded {
  transform: rotate(180deg);
}

.expand-button.subsection {
  padding: 4px;
}

.expand-button.bullet {
  padding: 2px;
  opacity: 0.7;
}

.bullet-header:hover .expand-button.bullet {
  opacity: 1;
}

.content-blur.subsection {
  height: 50px;
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(28, 28, 28, 0.95) 90%
  );
}

.subsection-content {
  animation: none;
}

.legal-bullet-point {
  animation: none;
  opacity: 1;
}

.legal-bullet-point:nth-child(1),
.legal-bullet-point:nth-child(2),
.legal-bullet-point:nth-child(3) {
  animation-delay: 0s;
}

.legal-section-date {
  font-size: 0.85rem !important;
  color: rgba(255, 255, 255, 0.5) !important;
  margin-bottom: 1rem !important;
}

.legal-contact {
  background: rgba(74, 222, 128, 0.1);
  border-radius: 10px;
  margin-top: 3rem;
  border: 1px solid rgba(74, 222, 128, 0.2);
  position: relative;
  overflow: hidden;
}

.legal-contact a {
  color: #4ade80;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
}

.legal-contact a:hover {
  color: #34D399;
  text-decoration: none;
  text-shadow: 0 0 10px rgba(74, 222, 128, 0.3);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes float {
  0%, 100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(20px, 20px);
  }
}

.legal-container::after {
  display: none;
}

@media (max-width: 768px) {
  .legal-title {
    font-size: 2rem;
  }

  .legal-section-content {
    font-size: 1rem;
  }

  .legal-header {
    padding: 1.5rem;
  }
}

@media print {
  .legal-container {
    background: white;
    padding: 0;
  }
  
  .legal-section {
    break-inside: avoid;
    background: none;
    border: none;
    box-shadow: none;
  }

  .legal-title {
    color: #000;
    -webkit-text-fill-color: initial;
  }

  .legal-section-content {
    color: #000;
  }
}

.scroll-progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, #4ade80, #34D399);
  transform-origin: 0%;
  z-index: 1000;
}

.section-transition {
  position: relative;
}

.section-transition::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(17, 17, 17, 0.8)
  );
  pointer-events: none;
}

.hover-lift {
  transition: transform 0.3s ease;
}

.hover-lift:hover {
  transform: translateY(-5px);
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

/* Enhanced legal introduction styling and hover effects */
.legal-introduction {
  position: relative;
  padding: 2rem;
  border-radius: 16px;
  background: rgba(28, 28, 28, 0.95);
  border: 1px solid rgba(74, 222, 128, 0.2);
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  overflow: hidden;
  margin-bottom: 1.5rem !important;
}

/* Gradient border effect */
.legal-introduction::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(74, 222, 128, 0.1),
    transparent
  );
  border-radius: 16px;
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  opacity: 0;
  z-index: 0;
}

/* Enhanced hover effects */
.legal-introduction:hover {
  transform: translateY(-4px) scale(1.005);
  border-color: rgba(74, 222, 128, 0.4);
  box-shadow: 
    0 8px 30px rgba(0, 0, 0, 0.3),
    0 0 20px rgba(74, 222, 128, 0.15),
    0 0 40px rgba(74, 222, 128, 0.1);
}

.legal-introduction:hover::before {
  opacity: 1;
  transform: scale(1.05);
}

/* Paragraph hover effects */
.legal-introduction p {
  position: relative;
  z-index: 1;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  padding: 0.5rem;
  border-radius: 8px;
}

.legal-introduction:hover p {
  color: rgba(255, 255, 255, 0.95);
  background: rgba(74, 222, 128, 0.03);
  transform: translateX(4px);
}

/* Key points section enhancements */
.legal-introduction .key-points {
  position: relative;
  z-index: 1;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  margin-top: 2rem;
}

.legal-introduction:hover .key-points {
  transform: translateY(-2px);
}

.legal-introduction .key-point {
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  border: 1px solid transparent;
}

.legal-introduction:hover .key-point {
  border-color: rgba(74, 222, 128, 0.1);
  background: rgba(74, 222, 128, 0.03);
}

/* Date info enhancements */
.legal-introduction .date-info {
  position: relative;
  z-index: 1;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.legal-introduction:hover .date-info {
  background: rgba(74, 222, 128, 0.08);
  transform: translateY(-2px);
}

/* Animated accent lines */
.legal-introduction::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(74, 222, 128, 0.3),
    transparent
  );
  transform: scaleX(0);
  transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.legal-introduction:hover::after {
  transform: scaleX(1);
}

/* Icon pulse animation on hover */
.legal-introduction:hover .key-point-icon {
  animation: introIconPulse 2s ease-in-out infinite;
}

@keyframes introIconPulse {
  0% {
    transform: scale(1);
    background: rgba(74, 222, 128, 0.08);
  }
  50% {
    transform: scale(1.1);
    background: rgba(74, 222, 128, 0.15);
  }
  100% {
    transform: scale(1);
    background: rgba(74, 222, 128, 0.08);
  }
}

.legal-section-wrapper .MuiDivider-root {
  margin: 1.5rem -2rem !important;
  width: calc(100% + 4rem);
}

.collapse-bar {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.75rem;
  background: linear-gradient(
    to top,
    rgba(28, 28, 28, 0.98) 0%,
    rgba(28, 28, 28, 0.7) 50%,
    rgba(28, 28, 28, 0) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
  margin-top: auto;
  opacity: 0;
  pointer-events: none;
}

.collapse-bar.expanded {
  opacity: 1;
  pointer-events: auto;
}

.collapse-bar-icon {
  color: #4ade80;
  transition: transform 0.3s ease;
}

.collapse-bar:hover .collapse-bar-icon {
  transform: translateY(-2px);
}

.MuiCollapse-root {
  position: relative !important;
  z-index: 2;
}

.MuiCollapse-wrapper {
  transform: translateZ(0);
  will-change: transform;
  pointer-events: auto;
}

.legal-section-content {
  position: relative;
  padding: 0 1rem;
  color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.bullet-point {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 0.5rem 0;
}

.bullet-icon {
  color: #4ade80;
  font-size: 1.2rem;
  margin-top: 0.2rem;
}

.MuiCollapse-entered {
  animation: none !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Enhanced icon animations and styles */
.subsection-icon, .bullet-icon, .section-icon {
  color: #4ade80;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: rgba(74, 222, 128, 0.08);
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(74, 222, 128, 0.1);
  backdrop-filter: blur(4px);
}

.subsection-header:hover .subsection-icon,
.bullet-header:hover .bullet-icon,
.legal-section-header:hover .section-icon {
  transform: scale(1.05) rotate(5deg);
  background: rgba(74, 222, 128, 0.15);
  border-color: rgba(74, 222, 128, 0.3);
  box-shadow: 0 0 15px rgba(74, 222, 128, 0.2);
}

/* Improved collapse button */
.collapse-bar {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  background: rgba(28, 28, 28, 0.95);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 10;
  margin-top: 1rem;
  opacity: 0;
  pointer-events: none;
}

.collapse-bar.expanded {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.collapse-bar:hover {
  background: rgba(74, 222, 128, 0.1);
  border-color: rgba(74, 222, 128, 0.3);
}

.collapse-bar-icon {
  color: #4ade80;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 4px;
  border-radius: 50%;
  background: rgba(74, 222, 128, 0.1);
}

.collapse-bar:hover .collapse-bar-icon {
  transform: translateY(-2px) scale(1.1);
  background: rgba(74, 222, 128, 0.2);
}

/* Enhanced expand/collapse icons */
.expand-button {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
  background: rgba(74, 222, 128, 0.08) !important;
  border: 1px solid rgba(74, 222, 128, 0.1) !important;
}

.expand-button:hover {
  background: rgba(74, 222, 128, 0.15) !important;
  border-color: rgba(74, 222, 128, 0.3) !important;
}

.expand-button svg {
  color: #4ade80;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.expand-button.expanded svg {
  transform: rotate(180deg);
}

/* Enhanced animations */
.legal-subsection {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: top;
}

.subsection-header {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.subsection-header:hover {
  transform: translateX(8px);
  background: rgba(74, 222, 128, 0.08);
}

.bullet-header {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.bullet-header:hover {
  background: rgba(74, 222, 128, 0.05);
}

/* Smooth collapse animations */
.MuiCollapse-root {
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
  scroll-margin-top: 2rem;
}

.MuiCollapse-entered {
  animation: none !important;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Enhanced section transitions */
.legal-section {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.legal-section:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
}

.legal-section.expanded {
  transform: translateY(0);
}

/* Smooth icon rotations */
.expand-button svg {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.expand-button.expanded svg {
  transform: rotate(180deg);
}

/* Enhanced bullet points */
.legal-bullet-point {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(74, 222, 128, 0.1);
}

.legal-bullet-point:hover {
  transform: translateX(4px);
  background: rgba(74, 222, 128, 0.05);
  border-color: rgba(74, 222, 128, 0.2);
}

/* Smooth description transitions */
.legal-description {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background: rgba(28, 28, 28, 0.6);
  backdrop-filter: blur(4px);
  border-top: 1px solid rgba(74, 222, 128, 0.1);
  margin-top: 0.5rem;
  padding: 1rem;
  border-radius: 0 0 8px 8px;
}

/* Key points styling */
.key-points {
  margin-top: 2rem;
  padding: 1.5rem;
  background: rgba(74, 222, 128, 0.08);
  border-radius: 12px;
  border: 1px solid rgba(74, 222, 128, 0.1);
}

.key-points-title {
  color: #4ade80;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.key-point {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid transparent;
}

.key-point:hover {
  background: rgba(74, 222, 128, 0.08);
  border-color: rgba(74, 222, 128, 0.2);
  transform: translateX(4px);
}

.key-point-icon {
  color: #4ade80;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  background: rgba(74, 222, 128, 0.08);
  border-radius: 6px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(74, 222, 128, 0.1);
}

.key-point:hover .key-point-icon {
  transform: scale(1.05) rotate(5deg);
  background: rgba(74, 222, 128, 0.15);
  border-color: rgba(74, 222, 128, 0.3);
}

/* Version info styling */
.version-info {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: rgba(74, 222, 128, 0.08);
  border-radius: 20px;
  border: 1px solid rgba(74, 222, 128, 0.1);
  color: #4ade80;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.version-info:hover {
  background: rgba(74, 222, 128, 0.12);
  border-color: rgba(74, 222, 128, 0.2);
  transform: translateY(-1px);
}

/* Date info styling */
.date-info {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  padding: 1rem;
  background: rgba(28, 28, 28, 0.5);
  border-radius: 12px;
  border: 1px solid rgba(74, 222, 128, 0.1);
}

.date-item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.date-label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.875rem;
}

.date-value {
  color: #4ade80;
  font-weight: 500;
}

/* Enhanced content preview and blur */
.legal-section-preview {
  padding: 1rem 2rem;
  margin: 0.5rem 0;
  position: relative;
  max-height: 120px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(28, 28, 28, 0.6);
  border-radius: 12px;
  border: 1px solid rgba(74, 222, 128, 0.1);
}

.content-blur {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(28, 28, 28, 0.7) 50%,
    rgba(28, 28, 28, 0.95) 100%
  );
  backdrop-filter: blur(4px);
  pointer-events: none;
}

/* Auto-scroll on expand/collapse */
.legal-section {
  scroll-margin-top: 2rem;
}

.legal-section-wrapper {
  padding: 0 2rem 1rem 2rem;
  position: relative;
  transform: translateZ(0);
  will-change: transform;
  z-index: 1;
  pointer-events: auto;
}

/* Enhanced metadata spacing */
.subsection-metadata {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  background: rgba(28, 28, 28, 0.6);
  border-radius: 20px;
  border: 1px solid rgba(74, 222, 128, 0.1);
  backdrop-filter: blur(4px);
}

/* Smooth collapse animations with scroll */
.MuiCollapse-root {
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
  scroll-margin-top: 2rem;
}

.MuiCollapse-entered {
  animation: none !important;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Remove any background from preview containers */
.legal-section .legal-section-preview,
.legal-subsection .legal-section-preview {
  background: transparent;
  border: none;
  border-radius: 0;
}

/* Enhanced section spacing and background */
.legal-section {
  position: relative;
  overflow: hidden;
}

/* Add decorative background pattern when collapsed */
.legal-section:not(.expanded)::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(28, 28, 28, 0.4) 20%,
    rgba(28, 28, 28, 0.95) 100%
  );
  pointer-events: none;
  backdrop-filter: blur(2px);
  border-radius: 0 0 16px 16px;
}

/* Add subtle border fade */
.legal-section:not(.expanded)::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(74, 222, 128, 0.2),
    transparent
  );
  opacity: 0.5;
}

/* Adjust spacing for collapsed state */
.legal-section:not(.expanded) {
  padding-bottom: 20px;
  margin-bottom: 1.5rem;
}

/* Enhance the expand/collapse button */
.expand-button {
  position: relative;
  z-index: 2;
}

/* Enhanced paper components with smooth hover effects */
.legal-introduction,
.legal-section,
.legal-contact {
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  border: 1px solid rgba(74, 222, 128, 0.2);
  background: rgba(28, 28, 28, 0.95);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
}

.legal-introduction:hover,
.legal-section:hover,
.legal-contact:hover {
  transform: translateY(-4px) scale(1.005);
  border-color: rgba(74, 222, 128, 0.4);
  box-shadow: 
    0 8px 30px rgba(0, 0, 0, 0.3),
    0 0 20px rgba(74, 222, 128, 0.15),
    0 0 40px rgba(74, 222, 128, 0.1);
}

/* Enhanced contact section */
.legal-contact {
  padding: 2rem !important;
  border-radius: 16px !important;
  position: relative;
}

.legal-contact::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(74, 222, 128, 0.3),
    transparent
  );
}

.legal-contact .legal-section-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem !important;
}

.legal-contact a {
  color: #4ade80;
  text-decoration: none;
  position: relative;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  border-radius: 4px;
}

.legal-contact a:hover {
  color: #34D399;
  background: rgba(74, 222, 128, 0.1);
}

.legal-contact a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0.5rem;
  right: 0.5rem;
  height: 1px;
  background: #4ade80;
  transform: scaleX(0);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.legal-contact a:hover::after {
  transform: scaleX(1);
}

/* Smoother section transitions */
.legal-section-header,
.subsection-header,
.bullet-header {
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.legal-section-header:hover,
.subsection-header:hover,
.bullet-header:hover {
  transform: translateX(4px);
}

/* Enhanced icon animations */
.section-icon,
.subsection-icon,
.bullet-icon {
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.legal-section:hover .section-icon,
.subsection-header:hover .subsection-icon,
.bullet-header:hover .bullet-icon {
  transform: scale(1.1) rotate(5deg);
  background: rgba(74, 222, 128, 0.15);
  border-color: rgba(74, 222, 128, 0.3);
}

/* Smoother expand/collapse animations */
.MuiCollapse-root {
  transition: height 0.4s cubic-bezier(0.34, 1.56, 0.64, 1) !important;
}

.expand-button {
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1) !important;
}

.expand-button svg {
  transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* Enhanced metadata hover effects */
.legal-metadata,
.date-info,
.key-points {
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.legal-metadata:hover,
.date-info:hover,
.key-points:hover {
  transform: translateY(-2px) scale(1.005);
  background: rgba(74, 222, 128, 0.12);
  border-color: rgba(74, 222, 128, 0.3);
}

/* Enhanced title hover effects */
.legal-section-title,
.legal-subsection-title {
  position: relative;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.legal-section-title::after,
.legal-subsection-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #4ade80;
  transform: scaleX(0);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: left;
}

.legal-section:hover .legal-section-title::after,
.legal-subsection:hover .legal-subsection-title::after {
  transform: scaleX(1);
}

/* Enhanced hover glow effects */
.legal-introduction:hover,
.legal-section:hover,
.legal-contact:hover {
  transform: translateY(-4px) scale(1.005);
  border-color: rgba(74, 222, 128, 0.4);
  box-shadow: 
    0 8px 30px rgba(0, 0, 0, 0.3),
    0 0 20px rgba(74, 222, 128, 0.15),
    0 0 40px rgba(74, 222, 128, 0.1);
}

/* Add subtle pulse animation to icons */
@keyframes iconPulse {
  0% {
    transform: scale(1) rotate(0);
  }
  50% {
    transform: scale(1.1) rotate(5deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}

.section-icon,
.subsection-icon,
.bullet-icon {
  animation: iconPulse 3s ease-in-out infinite;
  animation-play-state: paused;
}

.legal-section:hover .section-icon,
.subsection-header:hover .subsection-icon,
.bullet-header:hover .bullet-icon {
  animation-play-state: running;
}

/* Add space between contact and footer */
.legal-contact {
  margin-bottom: 1.5rem !important;
}

/* Enhanced smooth dropdown animations */
.MuiCollapse-root {
  transition: height 0.4s cubic-bezier(0.34, 1.56, 0.64, 1) !important;
}

.MuiCollapse-wrapper {
  transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1) !important;
}

.MuiCollapse-wrapperInner {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1) !important;
}

.MuiCollapse-entered .MuiCollapse-wrapperInner {
  opacity: 1;
  transform: translateY(0);
}

/* Smooth section content transitions */
.legal-section-content,
.legal-description,
.bullet-points {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.MuiCollapse-entered .legal-section-content,
.MuiCollapse-entered .legal-description,
.MuiCollapse-entered .bullet-points {
  opacity: 1;
  transform: translateY(0);
}

/* Enhanced expand/collapse icon animation */
.expand-button svg {
  transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.expand-button.expanded svg {
  transform: rotate(180deg);
}

/* Smooth section expansion */
.legal-section-wrapper {
  transition: padding 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.legal-section.expanded .legal-section-wrapper {
  padding-bottom: 2rem;
}

/* Adjust spacing between introduction and sections */
.legal-introduction {
  margin-bottom: 1.5rem !important;
}

/* Reduce empty space in collapsed sections */
.legal-section:not(.expanded) {
  padding-bottom: 20px; /* Reduced from 40px */
  margin-bottom: 1.5rem;
}

/* Enhanced fade effect for collapsed sections */
.legal-section:not(.expanded)::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px; /* Increased height for smoother fade */
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(28, 28, 28, 0.4) 20%,
    rgba(28, 28, 28, 0.95) 100%
  );
  pointer-events: none;
  backdrop-filter: blur(2px);
  border-radius: 0 0 16px 16px;
}

/* Add subtle border fade */
.legal-section:not(.expanded)::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(74, 222, 128, 0.2),
    transparent
  );
  opacity: 0.5;
}

/* Adjust section wrapper spacing */
.legal-section-wrapper {
  padding: 0 2rem 1rem 2rem; /* Reduced bottom padding */
}

/* Enhance section hover effect */
.legal-section:not(.expanded):hover::after {
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(28, 28, 28, 0.5) 20%,
    rgba(28, 28, 28, 0.98) 100%
  );
  height: 120px; /* Slightly increase fade height on hover */
} 